/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'

import { buildStandaloneLinkStyle } from './Link'
import { media, typeRamp, colors, themeAccentColorMap } from '../../constants'
import { accentProps, withAccentTheme } from '../../context/AccentThemeContext'

export const TEXT_SIZE_NORMAL = 'normal'
export const TEXT_SIZE_MEDIUM = 'medium'
export const TEXT_SIZE_SMALL = 'small'

const propTypes = {
	children: PropTypes.node.isRequired,
	style: PropTypes.object,
	defaultParagraph: PropTypes.oneOf([
		TEXT_SIZE_NORMAL,
		TEXT_SIZE_MEDIUM,
		TEXT_SIZE_SMALL,
	]),
	...accentProps,
}

const defaultProps = {
	children: [],
	style: {},
	defaultParagraph: TEXT_SIZE_NORMAL,
}

const sizeMap = {
	[TEXT_SIZE_NORMAL]: {
		...typeRamp.light_20,
		[media.large]: {
			...typeRamp.light_30,
		},
	},
	[TEXT_SIZE_MEDIUM]: {
		...typeRamp.light_20,

		[media.large]: {
			...typeRamp.light_20,
		},
	},
	[TEXT_SIZE_SMALL]: {
		...typeRamp.light_16,

		[media.large]: {
			...typeRamp.light_16,
		},
	},
}

const Text = ({ children, defaultParagraph, style, accentTheme }) => {
	return (
		<div
			css={[
				{
					...sizeMap[defaultParagraph],

					[['& h1', '& h2', '& p.--headline']]: {
						...typeRamp.light_40,
						[media.large]: {
							...typeRamp.light_80,
						},
					},

					[[
						'&.--eyebrow',
						'& h1.--eyebrow',
						'& h2.--eyebrow',
						'& h3.--eyebrow',
					]]: {
						...typeRamp.light_16,
						[media.mediumUp]: {
							...typeRamp.light_30,
						},
					},

					[['& h3']]: {
						...typeRamp.med_20,
					},

					[['& h3.--small']]: {
						...typeRamp.med_16,
					},

					[[
						'& h1 a',
						'& h2 a',
						'& p.--headline a',
					]]: buildStandaloneLinkStyle(),

					// Paragraphs

					'& p.--small': {
						...typeRamp.light_16,
					},

					'& p.--medium': {
						...typeRamp.light_20,
					},

					'& p.--large': {
						...typeRamp.light_30,
						[media.mediumUp]: {
							...typeRamp.light_50,
						},
					},

					// Block quotes

					'& blockquote > p': {
						...typeRamp.light_20,
						[media.mediumUp]: {
							...typeRamp.light_30,
						},
					},

					'& blockquote > .--medium': {
						...typeRamp.light_20,
						[media.mediumUp]: {
							...typeRamp.light_20,
						},
					},

					'& blockquote > p.--large': {
						...typeRamp.light_20,
						[media.mediumUp]: {
							...typeRamp.light_50,
						},
					},

					'& blockquote footer': {
						marginTop: '3rem',
					},

					// Lists

					'& li': {
						listStyleType: 'none',
						position: 'relative',
						margin: 0,
						padding: '0 0 0 1.5rem',
						'&:before': {
							content: '" "',
							background: themeAccentColorMap[accentTheme],
							width: '0.375rem',
							height: '0.375rem',
							display: 'inline-block',
							position: 'absolute',
							left: 0,

							borderRadius: '1rem',
							transform: 'translateY(0.65rem)',

							[media.large]: {
								transform: 'translateY(0.75rem)',
							},
						},
					},

					'& ul': {
						...typeRamp.light_16,

						'& > * + *': {
							marginTop: '1rem',
						},
					},

					'& ul.--unstyled': {
						'& li': {
							paddingLeft: 0,
							padding: '0 1.5rem 0rem 0',
						},

						'& li:before': {
							display: 'none',
						},
					},

					'& ul.--unstyled-mediumUp': {
						[media.mediumUp]: {
							'& li': {
								paddingLeft: 0,
								padding: '0 1.5rem 0rem 0',
							},

							'& li:before': {
								display: 'none',
							},
						},
					},

					'& ul.--col-2': {
						[media.mediumUp]: {
							columns: '2 auto',
							columnGap: '3rem',
						},
					},

					// Colors

					'& *.--grey': {
						color: colors.grey,
					},

					// vSpacing

					'& * + *': {
						[media.small]: {
							marginTop: '1.5rem',
						},
						marginTop: '2rem',
					},

					'& h3 + *': {
						marginTop: '1.25rem',
					},

					'& h4 + *': {
						marginTop: '0.25rem',
					},

					'& .--eyebrow + *': {
						marginTop: '0.5rem',
					},

					'& cite': {
						display: 'block',
						fontStyle: 'normal',
						borderLeft: '2px solid #EEEEEE',
						paddingLeft: '1rem',
						color: colors.grey,
					},
				},
				style,
			]}
		>
			{children}
		</div>
	)
}

Text.propTypes = propTypes
Text.defaultProps = defaultProps

export default withAccentTheme(Text)
