import React from 'react'
import PropTypes from 'prop-types'

import { getDisplayName } from '../containers/utils'
import {
	THEME_PEACH,
	THEME_CITRON,
	THEME_GREEN,
	THEME_PERIWINKLE,
} from '../constants'

export const orderedAccentThemes = [
	THEME_PEACH,
	THEME_CITRON,
	THEME_GREEN,
	THEME_PERIWINKLE,
]

export const accentProps = {
	accentTheme: PropTypes.oneOf(orderedAccentThemes).isRequired,
}

export const relativeAccentTheme = (fromAccent, distance) => {
	if (!fromAccent) {
		// If there is no supplied from spot, just return the first one
		return orderedAccentThemes[0]
	}
	const accentIDX = orderedAccentThemes.indexOf(fromAccent)
	// https://stackoverflow.com/questions/15321304/efficient-way-to-circle-through-an-array
	const relativeIDX =
		(accentIDX + orderedAccentThemes.length + distance) %
		orderedAccentThemes.length
	return orderedAccentThemes[relativeIDX]
}

////////

const AccentThemeContext = React.createContext({
	accentTheme: orderedAccentThemes[0],
})

export default AccentThemeContext

export const withAccentTheme = Component => {
	const C = props => {
		return (
			<AccentThemeContext.Consumer>
				{accentProps => <Component {...props} {...accentProps} />}
			</AccentThemeContext.Consumer>
		)
	}

	C.displayName = `WithAccentTheme(${getDisplayName(Component)})`
	return C
}

export const provideAccentTheme = (Component, accentTheme) => {
	const C = props => {
		return (
			<AccentThemeContext.Provider value={{ accentTheme }}>
				<AccentThemeContext.Consumer>
					{accentProps => <Component {...props} {...accentProps} />}
				</AccentThemeContext.Consumer>
			</AccentThemeContext.Provider>
		)
	}

	C.displayName = `ProvideAccentTheme(${getDisplayName(Component)})`
	return C
}
